import React from "react"

const Exist = () => (
    <svg width="271" height="369" viewBox="0 0 271 369" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>Illustration of a man having a conversation, and high fiving, someone</desc>
        <path d="M26.0999 355.4C26.0999 355.4 48.6 352.1 48.2 362.7L12.2 362.3C12.9 357.5 12.6999 352.5 16.4999 345.2H27.4999C27.4999 345.1 26.9999 352.9 26.0999 355.4Z" fill="white"/>
        <path d="M85.1 362.7C86.2 364.3 85.9 367.3 85.9 367.3H49L49.1 362.2L85.1 362.7Z" fill="#61D0EC"/>
        <path d="M85.2 362.7L49.2 362.3C49.9 357.5 49.6999 352.5 53.4999 345.2H64.4999C64.4999 345.2 64.6 352.3 63.7 354.7C63.6 354.7 82.9 352.4 85.2 362.7Z" fill="white"/>
        <path d="M48.2 362.7C49.3 364.3 49 367.3 49 367.3H12.1L12.2 362.2L48.2 362.7Z" fill="#61D0EC"/>
        <path d="M10.2999 238.2C7.19994 236.7 4.79997 233.5 3.69997 230.4C2.39997 226.8 2.29997 222.9 2.69997 219.1C2.89997 217.6 3.09994 216.1 3.29994 214.6C3.39994 213.8 3.59997 213 3.69997 212.1L6.49995 211.7L12.2 210.9C12.3 211.8 12.5999 212.6 12.7999 213.4C12.9999 214.2 13.3 215 13.6 215.8C14.5 217.9 15.2 220.1 16 222.2C16.5 223.6 16.7999 225.6 15.2999 226.5C14.5999 226.9 13.6 226.6 13.5 225.7C13.2 223.5 11.4 219.5 9.49995 219.3C7.79995 219.2 6.29996 221.5 7.09996 223.9C7.09996 224.2 6.39995 229.3 8.89995 232.6C8.89995 232.6 8.89995 232.6 8.99995 232.7C8.99995 232.7 8.99995 232.7 8.99995 232.8C8.99995 232.8 8.99996 232.8 9.09996 232.8C9.09996 232.8 9.19994 232.9 9.29994 233C10.0999 234 12.2999 236.8 12.2999 238C12.2999 238.3 12.0999 238.5 11.7999 238.5C11.2999 238.6 10.7999 238.4 10.2999 238.2Z" fill="white"/>
        <path d="M14.9001 229.4C12.9001 228.1 10.1001 227 10.1001 227C8.50007 226.1 7.60008 225 7.20008 223.9C6.40008 221.5 7.90007 219.1 9.60007 219.3C11.5001 219.5 13.3001 223.5 13.6001 225.7C13.7001 226.6 14.6001 226.9 15.4001 226.5C17.0001 225.6 16.6001 223.7 16.1001 222.2C15.3001 220.1 14.5001 217.9 13.7001 215.8C13.4001 215 13.1001 214.2 12.9001 213.4C12.7001 212.7 12.4001 211.8 12.3001 210.9L13.9001 210.7L14.9001 202.8C14.9001 202.8 33.8001 211.1 57.6001 207.8C61.3001 207.3 65.2001 206.5 69.1001 205.3L69.8001 205.1L84.7001 268.3C85.6001 272.1 85.5001 276.1 84.4001 279.9L65.4001 345.1H64.5001H53.5001H52.3001L64.3001 277.9L45.0001 233.5L40.0001 276.7L27.5001 345.1H16.5001L17.4001 278.1L14.9001 236.1C15.0001 236.1 15.2001 236.1 15.3001 236C16.7001 235.6 15.7001 233.1 14.2001 232.2C14.3001 232.2 17.6001 231.2 14.9001 229.4Z" fill="white"/>
        <path d="M10.1 227C10.1 227 12.8 228 14.9 229.4C17.6 231.2 14.3 232.3 14.3 232.3C14.2 232.2 14.1 232.2 14 232.1C12.3 231.3 11.2 230.1 11.2 230.1C10.8 229.6 10 228.9 10.1 227Z" fill="white"/>
        <path d="M9.00002 232.6C6.50002 229.3 7.10003 224.2 7.20003 223.9C7.60003 225 8.50003 226.1 10.1 227C10 228.9 10.8 229.7 11.2 230.1C11.2 230.1 12.3 231.3 14 232.1C14.1 232.1 14.2 232.2 14.3 232.3C15.8 233.2 16.8 235.7 15.4 236.1C15.3 236.1 15.1 236.2 15 236.2C12.8 236.5 10.3 234.1 9.40002 233.1C9.30002 233 9.30003 233 9.20003 232.9C9.20003 232.9 9.20003 232.9 9.10003 232.9C9.10003 232.8 9.10002 232.8 9.00002 232.6C9.00002 232.7 9.10002 232.7 9.00002 232.6Z" fill="white"/>
        <path d="M174.8 110.5C174.8 118.2 174.8 125.8 174.8 125.8C174.8 125.8 174.7 130.8 167.8 130.8L160.9 133.8L160.5 133.9L158.5 123.2L166.2 118.6C166.8 117.1 167.4 115.5 168.4 113.3C168.8 112.5 169.1 111.5 169.3 110.3L169.7 110.4C169.9 109.5 170.1 108.6 170.4 107.7C170.6 107 171 106.1 171.7 105.9C172.2 105.8 172.7 105.9 173.1 106.3C173.5 106.6 173.7 107.1 173.9 107.5C174.3 108.4 174.5 109.5 174.5 110.5H174.8Z" fill="white"/>
        <path d="M174.8 110.5C174.8 105.3 174.8 100 174.8 96.9C174.8 89.1 177.8 89.2 178.8 95.4C179.5 100.2 179.6 106.2 180.4 110.3L180 110.4C179.8 109.5 179.6 108.6 179.3 107.7C179.1 107 178.7 106.1 178 105.9C177.5 105.8 177 105.9 176.6 106.3C176.2 106.6 176 107.1 175.8 107.5C175.4 108.4 175.2 109.5 175.2 110.5H174.8Z" fill="white"/>
        <path d="M174.8 96.9C174.8 100 174.8 105.3 174.8 110.5H174.5C174.5 109.5 174.3 108.4 173.9 107.5C173.7 107 173.4 106.6 173.1 106.3C172.7 106 172.2 105.8 171.7 105.9C171 106.1 170.6 106.9 170.4 107.7C170.1 108.6 169.9 109.5 169.7 110.4L169.3 110.3C170.1 106.2 170.2 100.2 170.9 95.4C171.8 89.2 174.8 89.1 174.8 96.9Z" fill="white"/>
        <path d="M174.8 125.8C174.8 125.8 174.8 118.3 174.8 110.5H175.1C175.1 109.5 175.3 108.4 175.7 107.5C175.9 107 176.2 106.6 176.5 106.3C176.9 106 177.4 105.8 177.9 105.9C178.6 106.1 179 106.9 179.2 107.7C179.5 108.6 179.7 109.5 179.9 110.4L180.3 110.3C180.5 111.5 180.8 112.5 181.2 113.3C182.2 115.4 182.8 117 183.4 118.6L190.9 122L191.2 122.1L188.9 132.7L181.6 130.9C175 130.8 174.8 125.8 174.8 125.8Z" fill="white"/>
        <path d="M132.5 144.4L132.6 144.6L125.6 147.3C123.9 148 122.1 148.3 120.3 148C117.8 147.6 115.3 147.1 112.7 146.5C114.9 144.7 117.5 143.3 120.3 142.7C124.4 141.9 128.8 142.5 132.5 144.4Z" fill="#eff1f6"/>
        <path d="M72.2 133.9C71 138.7 69.1 145 68.7 146.7C67.1 160.8 78.5999 202.6 78.5999 202.6L69.7 205.2L59.2 102L63.0999 103.9C63.0999 103.9 67.5 109.4 70.2 114.6C72.9 119.8 68.4 123.4 70.2 125.6C72 127.9 72.9 125.7 73.2 128.4C73.2 129.3 72.8 131.4 72.2 133.9Z" fill="#61D0EC"/>
        <path d="M151.3 128C151.8 128 152.2 127.6 152.2 127.1C152.2 126.6 151.8 126.2 151.3 126.2C150.8 126.2 150.4 126.6 150.4 127.1C150.4 127.6 150.8 128 151.3 128ZM155.5 126.7C156 126.7 156.4 126.3 156.4 125.8C156.4 125.3 156 124.9 155.5 124.9C155 124.9 154.6 125.3 154.6 125.8C154.6 126.3 155 126.7 155.5 126.7ZM63.1 103.8L93.6 118.7L121.4 130.6L157.9 120.8L158.3 123.1L160.3 133.8V133.9L132.4 144.6L132.3 144.4C128.6 142.5 124.2 141.8 120.1 142.7C117.3 143.3 114.7 144.7 112.5 146.5C99.7 143.7 86.1 139.3 72 133.8C72.6 131.3 73 129.2 72.9 128.4C72.7 125.7 71.8 127.8 69.9 125.6C68.1 123.3 72.6 119.8 69.9 114.6C67.5 109.4 63.1 103.8 63.1 103.8Z" fill="#61D0EC"/>
        <path d="M5.40005 144.3C8.00005 147.1 9.30006 150.5 9.00006 153.8C8.70006 157.9 6.00005 161.8 1.80005 164.5L1.90005 155.8C1.90005 153.3 2.50006 150.8 3.50006 148.5L5.40005 144.3Z" fill="#eff1f6"/>
        <path d="M49.8 91.7C49.3 93 48.1 93.8 46.1 92.8C41.9 90.7 38.5 86.7 36 82.7C34.3 80 33 77.1 32 74.1C31.1 71.2 30.1 67.9 30.1 64.8C30.1 60.8 32.7 58.1 36.5 57.3C36.6 54.3 38.2 51.5 40.6 50.6C44.2 49.2 48 49 51.8 48.4C54.9 47.9 57.9 46.8 60.6 45.2C64.1 43.2 67.1 40.5 70 37.7C72.2 35.6 74.3 33.4 76.8 31.7C79.6 29.7 83.1 28.2 86.6 28.7C89.6 29.1 92.5 31 93.5 34C95.3 39.8 89.9 44.5 85.6 47.3C84 48.3 82.3 49.3 80.6 50.1L80.7 50.3C83.9 57.1 85.8 64.4 86.4 71.9C86.5 73.4 86.5 74.9 86.3 76.4C85.7 81 83.9 85.3 81.3 89.2C76.6 96.5 68.1 99.4 60.4 97.8C57.8 97.2 55.2 96.1 53 94.5C51.9 93.9 50.8 92.9 49.8 91.7ZM80.3 67.5C80.3 66.5 79.5 65.7 78.5 65.7C77.5 65.7 76.7 66.5 76.7 67.5C76.7 68.5 77.5 69.3 78.5 69.3C79.5 69.3 80.3 68.5 80.3 67.5Z" fill="white"/>
        <path d="M60.4001 98.1L60.3 99L59.2001 101.9C56.4001 102.7 53.2 101.6 49.8 99.4L52.8 95L53.0001 94.8C55.3001 96.4 57.8001 97.5 60.4001 98.1Z" fill="white"/>
        <path d="M69.0001 205.3C65.1001 206.5 61.2001 207.3 57.5001 207.8V207.4L52.7001 161.3C52.8001 159.8 52.6001 158.3 52.2001 156.8C57.0001 146.4 50.2 104.5 49.3 99.6L49.7001 99.5C53.1001 101.7 56.3001 102.8 59.1001 102L69.6001 205.2C69.5001 205.2 69.3001 205.3 69.0001 205.3Z" fill="white"/>
        <path d="M12.2 210.9L6.5 211.7L3.70001 212.1L1 212.5L1.70001 164.4C5.90001 161.7 8.59999 157.8 8.89999 153.7C9.19999 150.4 7.79999 147 5.29999 144.2L16.9 118.1C19.2 113.2 23.3 109.4 28.3 107.4L49.3 99C49.3 99 49.3 99.2 49.4 99.5C50.2 104.4 57 146.3 52.3 156.7L52.8 161.2L57.6 207.3V207.7C33.8 211 14.9 202.7 14.9 202.7L13.9 210.6L12.2 210.9ZM9 203.1C8.8 203.6 9.1 204.1 9.5 204.3C10 204.5 10.5 204.2 10.7 203.8C10.9 203.3 10.6 202.8 10.2 202.6C9.70001 202.3 9.2 202.6 9 203.1ZM9.39999 208.6C9.89999 208.8 10.4 208.5 10.6 208C10.8 207.5 10.5 207 10.1 206.8C9.60001 206.6 9.09999 206.9 8.89999 207.3C8.59999 207.9 8.89999 208.5 9.39999 208.6Z" fill="#61D0EC"/>
        <path d="M148.4 1.79999C172.8 1.79999 192.6 15.9 192.6 33.2C192.6 50.5 172.8 64.6 148.4 64.6C140.4 64.6 132.8 63.1 126.3 60.4C120.9 65.7 109.4 64.6 109.4 64.6C114.7 62.1 113.3 52.2 113.3 52.2C107.7 46.9 104.3 40.3 104.3 33.2C104.2 15.9 124 1.79999 148.4 1.79999Z" fill="white"/>
        <path d="M155.5 126.7C155.997 126.7 156.4 126.297 156.4 125.8C156.4 125.303 155.997 124.9 155.5 124.9C155.003 124.9 154.6 125.303 154.6 125.8C154.6 126.297 155.003 126.7 155.5 126.7Z" fill="#272223"/>
        <path d="M151.3 128C151.797 128 152.2 127.597 152.2 127.1C152.2 126.603 151.797 126.2 151.3 126.2C150.803 126.2 150.4 126.603 150.4 127.1C150.4 127.597 150.803 128 151.3 128Z" fill="#272223"/>
        <path d="M78.4999 65.7C79.4999 65.7 80.2999 66.5 80.2999 67.5C80.2999 68.5 79.4999 69.3 78.4999 69.3C77.4999 69.3 76.7 68.5 76.7 67.5C76.8 66.5 77.4999 65.7 78.4999 65.7Z" fill="#272223"/>
        <path d="M8.79999 207.5C8.99999 207 9.5 206.8 10 207C10.5 207.2 10.7 207.7 10.5 208.2C10.3 208.7 9.79999 208.9 9.29999 208.8C8.89999 208.5 8.59999 207.9 8.79999 207.5Z" fill="#272223"/>
        <path d="M8.99994 203.1C9.19994 202.6 9.69995 202.4 10.2 202.6C10.7 202.8 10.9 203.3 10.7 203.8C10.5 204.3 9.99994 204.5 9.49994 204.3C9.09994 204.1 8.79994 203.5 8.99994 203.1Z" fill="#272223"/>
        <path d="M12.5126 227.613C13.683 226.991 13.7283 224.787 12.6138 222.69C11.4993 220.593 9.64702 219.397 8.47662 220.019C7.30621 220.641 7.2609 222.846 8.37541 224.943C9.48991 227.04 11.3422 228.235 12.5126 227.613Z" fill="white"/>
        <path d="M174.8 96.9C174.8 89.1 171.8 89.2 170.8 95.4C170.1 100.2 170 106.2 169.2 110.3C169 111.5 168.7 112.5 168.3 113.3C167.3 115.4 166.7 117 166.1 118.6" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M167.8 130.8C174.7 130.8 174.8 125.8 174.8 125.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.4 111.5C174.4 111.2 174.5 110.9 174.5 110.6C174.5 109.6 174.3 108.5 173.9 107.6C173.7 107.1 173.4 106.7 173.1 106.4C172.7 106.1 172.2 105.9 171.7 106C171 106.2 170.6 107 170.4 107.8C170.1 108.7 169.9 109.6 169.7 110.5C169.6 110.9 169.6 111.2 169.5 111.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M158.4 123.2L166.1 118.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M167.8 130.8L160.9 133.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M181.8 130.8C174.9 130.8 174.8 125.8 174.8 125.8C174.8 125.8 174.8 118.3 174.8 110.5C174.8 105.3 174.8 100 174.8 96.9C174.8 89.1 177.8 89.2 178.8 95.4C179.5 100.2 179.6 106.2 180.4 110.3C180.6 111.5 180.9 112.5 181.3 113.3C182.3 115.4 182.9 117 183.5 118.6" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M175.2 111.5C175.2 111.2 175.1 110.9 175.1 110.6C175.1 109.6 175.3 108.5 175.7 107.6C175.9 107.1 176.2 106.7 176.5 106.4C176.9 106.1 177.4 105.9 177.9 106C178.6 106.2 179 107 179.2 107.8C179.5 108.7 179.7 109.6 179.9 110.5C180 110.9 180 111.2 180.1 111.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M191 121.9L183.5 118.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.2 138.8L20.9 155.1L14.9 202.8L13.9 210.7L12.3 210.9L6.59998 211.7L3.79996 212.1L1.09998 212.5L1.79996 164.4L1.89996 155.7C1.89996 153.2 2.49997 150.7 3.49997 148.4L5.39996 144.2L17 118.1C19.3 113.2 23.4 109.4 28.4 107.4L49.4 99C49.4 99 49.4 99.2 49.5 99.5C50.3 104.4 57.1 146.3 52.4 156.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M44.0001 106C42.7001 107.2 41.3001 108.4 40.6001 110C39.8001 111.6 39.8001 113.7 41.1001 115C41.9001 115.8 42.9 116.1 43.8 116.7C46.8 118.7 47.0001 123.3 45.2001 126.4C43.5001 129.5 40.3 131.6 37.3 133.5C37.3 133.5 49.6 145.2 52.3 156.7C52.7 158.2 52.8 159.7 52.8 161.2C52.8 161.4 52.8 161.5 52.8 161.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M68.7 146.6C68.6 146.8 68.6 147 68.6 147" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M63.1 103.8C63.1 103.8 67.5 109.3 70.2 114.5C72.9 119.7 68.4 123.3 70.2 125.5C72 127.8 72.9 125.6 73.2 128.3C73.3 129.1 72.9 131.2 72.3 133.7C71.1 138.5 69.2 144.8 68.8 146.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M52.9 95L49.9 99.4C53.3 101.6 56.5 102.7 59.3 101.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M49.8 91.7C50.8 92.9 51.8001 93.9 53.0001 94.7C55.2001 96.4 57.8001 97.5 60.4001 98C68.0001 99.6 76.6 96.6 81.3 89.4C83.8 85.5 85.7 81.2 86.3 76.6C86.5 75.1 86.5001 73.6 86.4001 72.1C85.8001 64.6 83.9001 57.3 80.7001 50.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M49 86.6C50.1 87.9 50.4 90.2 49.8 91.7C49.3 93 48.1 93.8 46.1 92.8C41.9 90.7 38.5 86.7 36 82.7C34.3 80 33 77.1 32 74.1C31.1 71.2 30.1 67.9 30.1 64.8C30.1 60.8 32.7 58.1 36.5 57.3C36.6 54.3 38.2 51.5 40.6 50.6C44.2 49.2 48 49 51.8 48.4C54.9 47.9 57.9 46.8 60.6 45.2C64.1 43.2 67.1 40.5 70 37.7C72.2 35.6 74.3 33.4 76.8 31.7C79.6 29.7 83.1 28.2 86.6 28.7C89.6 29.1 92.5 31 93.5 34C95.3 39.8 89.9 44.5 85.6 47.3C84 48.3 82.3 49.3 80.6 50.1C78.1 51.4 75.4 52.4 72.7 53.2C70.4 53.9 68.1 54.4 65.7 54.8C63.4 55.2 61 55.6 58.8 56.6C56.3 57.9 54.2 60.4 54.4 63.2C54.5 64.6 55.3 66 56 67.3C56.8 68.8 57.5 70.4 57.1 72.1C56.9 72.9 56.4 73.7 55.7 74.1C54.1 75 52.7 74 52 72.7C51.8 72.2 51.6 71.7 51.5 71.2C51.3 70 50.9 68.9 50.4 67.8L50 67.1C49 65 46.8 63.7 44.5 63.7H44.2C40.8 63.7 38 66.5 38 69.9V72.7C38 74.9 39.1 76.9 41 78L44.1 79.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M73.9 63.1C74.8 61.9 76.4 61.3 77.9 61.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M43 69.1C43 69.1 45.9 69.4 47.7 71.8C48.3 72.6 48.7 73.6 48.9 74.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M47.7 71.8C47.7 71.8 43.9 71.3 42.6 73.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M89.2 36.4C89.2 38.9 87.6 41.2 85.7 42.7C83.7 44.2 81.4 45.1 79 45.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M46.9 53.3C52.4 53.4 58 51.5 62.4 48.2C66.2 45.2 69.3 41.1 73.8 39.4" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M34.4 65.4C33.3 72.3 36.3 79.8 41.9 84" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M39.7 62.6C40.7 61.3 42.2999 60.4 43.9999 60.4C45.6999 60.3 47.2999 61.1 48.3999 62.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M60.2999 99L59.2 101.9L63.0999 103.8L93.5999 118.7L121.4 130.6L157.9 120.8L158.3 123.1L160.3 133.8V133.9L132.4 144.6L125.4 147.3C123.7 148 121.9 148.3 120.1 148C117.6 147.6 115.1 147.1 112.5 146.5C99.6999 143.7 86.0999 139.3 71.9999 133.8H71.8999" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.2 367.3V362.2" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M48.2 362.7C49.3 364.3 48.9999 367.3 48.9999 367.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M52.2 156.7L52.7 161.2L57.4999 207.4" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.1 227C10 228.9 10.8 229.7 11.2 230.1C11.2 230.1 12.3 231.3 14 232.1C14.1 232.1 14.2 232.2 14.3 232.3C15.8 233.2 16.8 235.7 15.4 236.1C15.3 236.1 15.1 236.2 15 236.2C12.8 236.5 10.3 234.1 9.40002 233.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.00008 232.7C9.00008 232.7 9.10008 232.7 9.00008 232.7C9.10008 232.8 9.10008 232.8 9.10008 232.9C9.10008 232.9 9.10009 232.9 9.20009 232.9C9.20009 232.9 9.30007 233 9.40007 233.1C10.2001 234.1 12.4001 236.9 12.4001 238.1C12.4001 238.4 12.2001 238.6 11.9001 238.6C11.4001 238.6 10.8001 238.5 10.4001 238.3C7.30007 236.8 4.90006 233.6 3.80006 230.5C2.50006 226.9 2.40006 223 2.80006 219.2C3.00006 217.7 3.20007 216.2 3.40007 214.7C3.50007 213.9 3.70006 213.1 3.80006 212.2C3.80006 212.2 3.80006 212.2 3.80006 212.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.20007 223.9C7.20007 224.2 6.50006 229.3 9.00006 232.6" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.2 210.8C12.2 210.9 12.2 210.9 12.2 210.8C12.3 211.7 12.6 212.6 12.8 213.4C13 214.2 13.3 215 13.6 215.8C14.5 217.9 15.2 220.1 16 222.2C16.5 223.6 16.8 225.6 15.3 226.5C14.6 226.9 13.6 226.6 13.5 225.7C13.2 223.5 11.4 219.5 9.49997 219.3C7.79997 219.2 6.29998 221.5 7.09998 223.9C7.49998 225 8.39997 226.1 9.99997 227C9.99997 227 12.7 228 14.8 229.4C17.5 231.2 14.2 232.3 14.2 232.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.8 202.8C14.8 202.8 33.7001 211.1 57.5001 207.8C61.2001 207.3 65.1001 206.5 69.0001 205.3C69.2001 205.2 69.5001 205.2 69.7001 205.1L59.2001 101.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M69.7 205.1L84.6 268.3C85.5 272.1 85.4 276.1 84.3 279.9L65.3 345.1H64.4H53.4H52.2L64.2 277.9L45 233.5L47.7 214.2" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.9 236.2L17.4 278.2L16.5 345.1H27.5L40 276.7L45 233.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M27.5001 345.1C27.5001 345.1 27.1001 352.9 26.2001 355.4C26.2001 355.4 50.9 351.5 48.3 362.7L12.3 362.3C13 357.5 12.8001 352.5 16.6001 345.2" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M49.1 367.3V362.2" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M85.1 362.7C86.2 364.3 85.9 367.3 85.9 367.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M64.4 345.1C64.4 345.1 64 352.2 63.1 354.7C63.1 354.7 85.1 351.9 85.1 362.6L49.1 362.2C49.8 357.4 49.6 352.4 53.4 345.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.69995 367.3H12.2H48.9999H49.0999H85.8999H90.0999" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M181.8 130.8L189.1 132.6L191.4 122L191.8 119.8L216.6 124.2" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M189.1 132.6L216.6 140.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.4 85.1C174.5 81.8 174.6 78.6 174.6 75.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M177.8 85.1C179.1 83.7 180.4 82.4 181.6 81" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M170.9 86.3C169.6 84.9 168.2 83.5 166.9 82.1" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M113.2 52.3C113.2 52.3 114.7 62.1 109.3 64.7C109.3 64.7 120.8 65.8 126.2 60.5C132.7 63.2 140.3 64.7 148.3 64.7C172.7 64.7 192.5 50.6 192.5 33.3C192.5 16 172.7 1.89999 148.3 1.89999C123.9 1.89999 104.1 16 104.1 33.3C104.2 40.4 107.6 47 113.2 52.3Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M176.9 20.6C175.3 23.5 170.8 24.8 167.6 23.3C165.3 22.2 163.4 20 160.9 20.3C158 20.7 156.3 24.3 153.4 23.9C150.7 23.6 149.7 20.1 147 20C144.1 20 142.8 23.9 139.9 23.8C137.5 23.7 136.5 20.8 134.2 20.1C132.8 19.7 131.3 20.2 130 20.9C128.8 21.6 127.6 22.4 126.1 22.7C124.7 23 122.9 22.5 122.4 21.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M122.5 35.7C124.3 34.1 127.1 33.8 129.6 34.1C132.1 34.5 134.5 35.4 136.9 35.9C140.1 36.6 143.4 36.7 146.6 36.8C148.2 36.8 149.9 36.9 151.5 36.5C153 36.1 154.3 35.4 155.8 35C157.2 34.6 158.6 34.5 160.1 34.5C161.7 34.5 163.3 34.6 164.8 34.8C167.5 35.2 170.7 35.9 172.8 34.4" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M168.5 47.3C163.5 48.8 157.6 48 153.3 45.3C152.9 46.9 151.1 48 149.3 48.3C147.5 48.6 145.6 48.3 143.7 48.2C141.2 48 138.7 48 136.2 48C131.6 48 127 48 122.4 48" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M232.7 42.8C212.3 42.8 195.8 54.6 195.8 69C195.8 83.5 212.3 95.2 232.7 95.2C239.4 95.2 245.7 93.9 251.2 91.7C255.7 96.1 265.3 95.2 265.3 95.2C260.9 93.1 262.1 84.9 262.1 84.9C266.8 80.5 269.6 75 269.6 69C269.6 54.6 253 42.8 232.7 42.8Z" fill="white"/>
        <path d="M262 85C262 85 260.8 93.2 265.2 95.3C265.2 95.3 255.6 96.2 251.1 91.8C245.7 94 239.4 95.3 232.6 95.3C212.2 95.3 195.7 83.5 195.7 69.1C195.7 54.6 212.2 42.9 232.6 42.9C253 42.9 269.5 54.7 269.5 69.1C269.6 75 266.8 80.5 262 85Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M95.9 367.3H105.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 285.1C22.7 294.5 22.3 303.9 20.9 313.2" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.5 224.2C70.3 232.9 73 241.6 75.8 250.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M75.6 295.5C73.2 302.5 70.9 309.5 68.5 316.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M41.1 234.4C39.4 242.5 37.8 250.6 36.1 258.7" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50.9 218.9C50.2 222.4 49.5 225.8 48.9 229.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.4 195.4C22.6 189 23.4 182.1 23 175.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M52.7 203C45.4 204.1 37.8 203.4 30.9 201" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.40005 144.3C8.00005 147.1 9.30006 150.5 9.00006 153.8C8.70006 157.9 6.00005 161.8 1.80005 164.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M112.7 146.6C114.9 144.8 117.5 143.4 120.3 142.8C124.4 141.9 128.8 142.5 132.5 144.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M159.3 128L148.5 131.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M190.3 127.1L201.2 129.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M68.2 205.6L69.0999 205.4L69.7999 205.2L78.7 202.6C78.7 202.6 67.1999 160.8 68.7999 146.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.5 211.8L6.60001 201" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0999 228.9L13.7 226.2" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M208.2 63C207.5 61.3 209.5 59.5 211.3 59.6C213.1 59.7 214.7 60.9 216.1 62C217.5 63.1 219.2 64.2 221 64C223 63.8 224.5 62.1 226.4 61.5C228 61 229.8 61.3 231.4 61.5C233.4 61.7 235.5 61.6 237.5 61.6C242.3 61.4 247.2 61.3 252 61.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M256.8 73C254 75.9 249.1 76.6 245.6 74.5C243.5 73.3 241.6 71.1 239.1 71.3C236.7 71.5 235.1 73.9 232.9 74.9C230.2 76.2 227 75.2 224 74.8C221 74.4 217.3 75.2 216.4 78" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Exist